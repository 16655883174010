<template>
  <v-layout column full-width>
    <v-layout align-center :class="[$vuetify.breakpoint.smAndDown && 'column']">
      <v-flex md6>
        <PregnancyNumberSelect
          v-model="value.number"
          :disabled="disabled"
        ></PregnancyNumberSelect>
      </v-flex>
      <v-flex md6>
        <BirthNumberSelect
          :class="$vuetify.breakpoint.smAndDown ? 'mt-3' : 'ml-4'"
          v-model="value.birth_number"
          :disabled="disabled"
        ></BirthNumberSelect>
      </v-flex>
    </v-layout>
    <v-layout column>
      <LabelTextField
        label="Przyjmowane leki w czasie ciąży"
        placeholder="Wpisz..."
        class="mt-3"
        :value.sync="value.drugs"
        :disabled="disabled"
        noMargins
        hide-details
      ></LabelTextField>
      <MaxlengthErrorMessage
        :value="value.drugs"
        :maxlength="2000"
      ></MaxlengthErrorMessage>
    </v-layout>
    <v-layout :class="['mt-3', $vuetify.breakpoint.smAndDown && 'column']">
      <v-flex md6>
        <LabelDateField
          label="Data porodu"
          placeholder="Wybierz..."
          :value.sync="value.birth_date"
          :disabled="disabled"
          noMargins
          hide-details
        ></LabelDateField>
      </v-flex>
      <v-flex md6>
        <v-layout align-end>
          <LabelTextField
            label="Tydzień rozwiązania (HBD)"
            placeholder="Wpisz tydzień..."
            v-mask="'##'"
            :value.sync="value.hbd"
            :disabled="disabled"
            :class="$vuetify.breakpoint.smAndDown ? 'mt-3 mr-2' : 'ml-4'"
            noMargins
            hide-details
          ></LabelTextField>
          <LabelTextField
            label="Liczba dni"
            placeholder="Wpisz liczbę dni..."
            v-mask="'##'"
            :value.sync="value.hbd_day"
            :disabled="disabled"
            :class="$vuetify.breakpoint.smAndDown ? 'mt-3 ml-2' : 'ml-4'"
            noMargins
            hide-details
          ></LabelTextField>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-layout :class="['mt-3', $vuetify.breakpoint.smAndDown && 'column']">
      <v-flex md6>
        <LabelTextField
          label="Masa dziecka (g)"
          v-mask="'#####'"
          placeholder="W gramach..."
          :value.sync="value.mass"
          :disabled="disabled"
          noMargins
          hide-details
        ></LabelTextField>
      </v-flex>
      <v-flex md6>
        <LabelTextField
          label="Długość dziecka (cm)"
          placeholder="W centymentrach..."
          v-mask="'#####'"
          :value.sync="value.length"
          :disabled="disabled"
          :class="$vuetify.breakpoint.smAndDown ? 'mt-3' : 'ml-4'"
          noMargins
          hide-details
        ></LabelTextField>
      </v-flex>
    </v-layout>
    <v-layout column class="mt-3 mb-1">
      <DefaultSubtitle class="mb-2">Rodzaj porodu</DefaultSubtitle>
      <v-radio-group
        v-model="value.pregnancy_type"
        :class="['mt-1 zg-text-control']"
        :row="!$vuetify.breakpoint.smAndDown"
        :disabled="disabled"
        hide-details
      >
        <v-radio
          class="zg-text-control"
          label="Siłami natury"
          :value="0"
        ></v-radio>
        <v-radio
          class="zg-text-control"
          label="Cesarskie cięcie"
          :value="1"
        ></v-radio>
        <v-radio
          class="zg-text-control"
          label="Kleszczowy i próżniowy"
          :value="2"
        ></v-radio>
      </v-radio-group>
    </v-layout>
    <v-layout column>
      <LabelTextField
        label="Powikłania porodu"
        placeholder="Wpisz..."
        class="mt-3"
        :value.sync="value.reason"
        :disabled="disabled"
        noMargins
        hide-details
      ></LabelTextField>
      <MaxlengthErrorMessage
        :value="value.reason"
        :maxlength="2000"
      ></MaxlengthErrorMessage>
    </v-layout>
    <v-layout column>
      <LabelTextField
        label="Uwagi"
        placeholder="Wpisz..."
        class="mt-3"
        :value.sync="value.comments"
        :disabled="disabled"
        noMargins
        hide-details
      ></LabelTextField>
      <MaxlengthErrorMessage
        :value="value.comments"
        :maxlength="2000"
      ></MaxlengthErrorMessage>
    </v-layout>
    <v-layout column>
      <LabelTextField
        label="Skala APGAR"
        placeholder="Wpisz..."
        class="mt-3"
        :value.sync="value.apgar"
        :disabled="disabled"
        noMargins
        hide-details
      ></LabelTextField>
      <MaxlengthErrorMessage
        :value="value.apgar"
        :maxlength="2000"
      ></MaxlengthErrorMessage>
    </v-layout>
    <v-layout column>
      <LabelTextField
        label="Uwagi do skali APGAR"
        placeholder="Wpisz..."
        class="mt-3"
        :value.sync="value.apgar_comments"
        :disabled="disabled"
        noMargins
        hide-details
      ></LabelTextField>
      <MaxlengthErrorMessage
        :value="value.apgar_comments"
        :maxlength="2000"
      ></MaxlengthErrorMessage>
    </v-layout>
  </v-layout>
</template>

<script>
export default {
  props: {
    value: {},
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    DefaultSubtitle: () => import("@/components/text/DefaultSubtitle"),
    LabelDateField: () => import("@/components/LabelDateField"),
    LabelTextField: () => import("@/components/LabelTextField"),
    PregnancyNumberSelect: () =>
      import("@/components/selects/PregnancyNumberSelect"),
    BirthNumberSelect: () => import("@/components/selects/BirthNumberSelect"),
    DefaultSelect: () => import("@/components/selects/DefaultSelect"),
    MaxlengthErrorMessage: () =>
      import("@/components/controls/MaxlengthErrorMessage"),
  },
};
</script>

<style scoped>
.zg-text-control ::v-deep label {
  color: rgba(0, 0, 0, 0.87);
}
</style>